import React, {useEffect, useState} from 'react';
import {
  Button,
  Grid,
  Typography,
  makeStyles, 
  useTheme
} from '@material-ui/core'
import {connect} from 'react-redux';
import {topicActions, authActions} from '_state/actions';
import {FormattedMessage} from 'react-intl';
import {CapeeshColors} from "../../../../assets/ColorPalette";
import AddSnippet from 'Components/AddSnippet';
import {makeGetRequest, makePostRequest} from "../../../../_state/helpers/api";
import {configWithAuth} from "../../../../_state/helpers";
import {history} from "_state/helpers";
import ImageUploadWithCrop from "../../../../Components/ImageUpload/ImageUploadWithCrop";
import {ImageCropType} from "../../../../Logic/CourseGroupConstants";
import CapeeshStyledModal from "../../../../Components/CapeeshStyledModal";

const useStyles = makeStyles(theme => ({
}));

const LessonInformationPanel = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const {user, organization, topic, computedMatch, usergroup, metadata, newCourse, auth, topicData,
    submitText = <FormattedMessage id="globalwords.save1" defaultMessage="Save"/>} = props;

  const [subjectName, setSubjectName] = useState('');
  const [description, setDescription] = useState('');
  
  const [hasAdminRights, setHasAdminRights] = useState(false);
  const [anchorSettingsMenu, setAnchorSettingsMenu] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  
  // image upload
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [topicImageData, setTopicImageData] = useState(false);

  useEffect(() => {
    if (topicData?.imageFileReferenceId) {
      makeGetRequest(`admin/organization/${organization.selectedOrganization}/image/file/reference/${topicData.imageFileReferenceId}/base64`, configWithAuth())
        .then(result => {
          setTopicImageData(result.data);
        })
        .catch(error => {
          console.log(error);
        });
    }

  }, [])

  useEffect(() => {
    
    user.info.roles.forEach(role => {
      if(role.organizationId == organization.selectedOrganization && role.role === "Admin")
        setHasAdminRights(true);
    });

    if( subjectName === "" && topic.loadingCurrentTopic === false) {
      setSubjectName(topicData.name);
      setDescription(topicData.description);
    }
    
  }, [topic]);

  const deleteTopic = () => {
    props.deleteTopic(organization.selectedOrganization, topicData.id);
    setDeleteConfirmation(false);

    //TODO: MORTEN show course group instead?
    if (computedMatch?.params?.courseGroupId) {
      history.push('/creator/course/group/' + computedMatch.params.courseGroupId);
    } else {
      history.push("/creator/");
    }
  };

  const handleSubmitForm = (e) => {
    e.preventDefault();
    props.updateTopic(organization.selectedOrganization, topicData.id, subjectName, topicData.topWordsCount, description, topicData.tags, topicData.lessonsOnly, null);
  };


  const handleCancel = () => {
    setDeleteConfirmation(false);
  };

  const showLanguagesPane = (event) => {
    props.onShowLanguagesPane();
  };

  const onDrop = (fileUploadResult) => {
    
    let organizationId = organization.selectedOrganization;
    
    const formData = new FormData();
    formData.append(fileUploadResult.imageFileName, fileUploadResult.image);
    
    // Upload Image
    makePostRequest("admin/organization/" + organizationId + "/image/?sharable=true", formData, configWithAuth('multipart/form-data'))
      .then(result => {
        const imageFileReferenceId = result.data["id"]
        props.updateTopic(organization.selectedOrganization, topicData.id, subjectName, topicData.topWordsCount, description, topicData.tags, topicData.lessonsOnly, imageFileReferenceId);

        // Get uploaded Image.
        makeGetRequest("admin/organization/" + organizationId + "/image/file/reference/" + imageFileReferenceId + "/base64", configWithAuth())
          .then(result => {
            setTopicImageData(result.data)
          })
          .catch(error => {
            console.log(error);
          });
      })
      .catch(error => {
        console.log(error);
      });
      console.log("done")
    
    setShowImageUpload(false);
  };

  return (
    <Grid container>
      {
        showImageUpload &&
        <ImageUploadWithCrop
          open={showImageUpload}
          updateImageDrop={onDrop}
          aspectRatioType={ImageCropType.Course}
          onCancel={() => setShowImageUpload(false)}
          title={"New topic image"}
        />
      }
      
      <Typography style={{marginTop: '10px' }} color="primary" variant='h6'>Topic image</Typography>

      {
        (user.organizationRole === "admin" || user.organizationRole === 'teacher') &&
        <Grid item xs={12} sm={12}>
          <Button id='settings-button'
                  variant={'contained'}
                  color='primary'
                  style={{
                    color: 'white',
                    height: '40px', 
                    marginRight: '15px',
                    textTransform: 'none'}}
                  onClick={() => (setShowImageUpload(!showImageUpload))}
          >
            {topicImageData ? "Change" : "Upload"} topic image
          </Button>
          
          {
            topicImageData ?
            <div style={{marginTop: '20px'}} key={topicImageData}>
              <img style={{ borderRadius:'10px', marginBottom: "20px" }} src={topicImageData} height={160}/>
            </div>
            :
            <Grid item xs={12} style={{ marginTop: '10px'}}>
              <Typography variant="h4"  style={{fontFamily: 'Lato', fontStyle: 'normal', fontWeight: 400, fontSize: '16px', lineHeight: '23px', background: 'white', backgroundColor:'white', borderRadius: '10px', padding: '20px',}}>
                <FormattedMessage id="dashboard.creator.lesson.no.image.added" defaultMessage={"No image has been added to the course. "} />
              </Typography>
            </Grid>
        }
        
        
        </Grid>
      }
      {
        (user.organizationRole === "admin") &&
        <Grid item xs={12} style={{}} >
          <div
            style={{ color: 'red', textDecorationLine:'underline', cursor: 'pointer', marginBottom: '20px'}}
            onClick={() => setDeleteConfirmation(true)}>
            Delete this topic
          </div>
        </Grid>
      }

      <CapeeshStyledModal
        open={deleteConfirmation}
        textTitle={"Delete the topic?"}
        textLine1={"Are you sure you wish to delete the topic? This cannot be undone."}
        onButton2={() => deleteTopic()}
        onButton1={() => handleCancel()}
        button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>}
        button1Variant={'outlined'}
        button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes, delete"/>}
        onClose={() => handleCancel()}
      />
    </Grid>
  );
};

LessonInformationPanel.propTypes = {};

const mapToProps = (state) => {
  return {
    organization: state.organization,
    topic: state.topic,
    user: state.user,
    metadata: state.metadata,
    auth: state.auth,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateTopic: (organizationId, topicId, name, topWordsCount, description, tags, lessonsOnly, imageFileReferenceId) => dispatch(topicActions.updateTopic(organizationId, topicId, name, topWordsCount, description, tags, lessonsOnly, imageFileReferenceId)),

  }
};

export default connect(mapToProps, mapDispatchToProps)(LessonInformationPanel)