import React, {useEffect, useState} from "react";
import {
    Card,
    Button,
    Grid,
    TextField,
    CardContent,
    Typography
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {connect} from 'react-redux';
import { createApi } from "unsplash-js";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {AdminUserCredentialType} from "../../../../Logic/CapeeshConstants";


const useStyles = makeStyles(theme => ({
  chip: {
    marginRight: '5px',
    marginBottom: '5px',
  },
  scenario: {
    paddingTop: '30px'
  },
  textFields: {
    marginBottom: '5px'
  }
}));


const ImageSearch = (props) => {
    const classes = useStyles();
    const [images, setImages] = useState([])
    const [keywords, setKeywords] = useState("");
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState("")

    const [unsplash, setUnsplash] = useState(null);
    
    useEffect(() => {
    
      const unsplashCert = props.user.info?.credentialsDtos?.find(x => x.adminUserCredentialType === AdminUserCredentialType.Unsplash)
      if (unsplashCert && !unsplash) {
        const tempUnsplash = new createApi({
          accessKey: unsplashCert.apiKey
        });  
        setUnsplash(tempUnsplash);
      }
    }, [])
    
    const setSearchTerms = (term) => {
      var cleanedString = term.replace('\n','');
      setKeywords(cleanedString);
    }
  
    const keyPress = (e) => {
      if(e.keyCode == 13){
        searchPhotos(null);
      }
    }
  
  
    const searchPhotos = async (e) => {
      if (!unsplash) {
        return;
      }
      let searchTerms = null
      if(e === null) {
        searchTerms = keywords
      } else {
        return      
      }
      
      unsplash.search
        .getPhotos({query: searchTerms, page: 1, perPage: 50})
        .then(searchResult => {
          if (searchResult.type === 'success') {
            setImages(searchResult.response.results)
            setOpen(true)
          } else {
            console.log(searchResult.message)
          }
        
        })
        
      
        
    };

    const handleImageChosen = (image, e) => {
      download(image)
    };

    const download = async (url) => {
      const {organization} = props;
      const response = await fetch(
        url
      );
      if (response.status === 200) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        var finalisedFile = new File([blob], "tname",{type: "image/jpeg", lastModified:new Date()})
        setFile(finalisedFile)
        props.onSelectImage(blob, keywords)
        setOpen(false)
        return { success: true };
      }
    };

    return (
      <div>
        <Dialog
          open={open}
          onClose={(event) => { setOpen(false)}}          
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ paddingLeft: '100px', paddingRight: '100px', cursor: 'pointer' }}
          maxWidth="lg"
          fullWidth
         >
            <DialogTitle id="alert-dialog-title">{"Select an image"}</DialogTitle>
            <DialogContent>
              <Card>    
                <CardContent className={classes.card} >
                  <Grid container>
                    <Grid item xs={12} sm={12} lg={12} className={classes.gridItem}>
                      <div className={classes.root}>
                          {images?.length > 0 ?
                            images.map((image, index) => (
                                <img key={image.urls.small} src={image.urls.small+""} alt={"an image"} style={{ maxHeight: '200px' }}
                                onClick={(e) => handleImageChosen(image.urls.regular, e) }/>
                            ))
                            :
                            <Typography variant="body1" component="p">
                              No results
                            </Typography>
                          }
                      </div>
                    </Grid>
     
                  </Grid> 
                </CardContent>
            </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={(event) => { setOpen(false)}} color="primary" autoFocus>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                style={{ width: '430px' }}
                placeholder="Image search keywords"
                margin="normal"
                value={keywords}
                multiline
                onChange={(event) => {setSearchTerms(event.target.value)}}
                onKeyDown={event => keyPress(event)}
                InputLabelProps={{
                    shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Button size="small" variant='outlined' onClick={event => searchPhotos(null)}>
                  Search
              </Button>
            </Grid>
        </Grid>
      </div>
    );
};

ImageSearch.propTypes = {};

const mapToProps = (state) => {
    return {
      metadata: state.metadata,
      organization: state.organization,
      user: state.user,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
};

export default connect(mapToProps, mapDispatchToProps)(ImageSearch)